



















import dayjs from "dayjs";
import * as Config from "@/config/constants";
import IconCreate from "@/icons/plus-solid.svg";
import IconUpdate from "@/icons/pen-solid.svg";
import IconDelete from "@/icons/minus-solid.svg";

const ACTION_TITLES = {
  create: "Création",
  update: "Mise à jour",
  delete: "Suppression",
};

export default {
  name: "LogTimeentriesLine",
  props: {
    log: {},
  },
  components: {
    IconCreate,
    IconUpdate,
    IconDelete,
  },
  computed: {
    loggedAt() {
      return dayjs(this.log.logged_at.date).format(
        Config.DATE_FORMAT + " à " + Config.TIME_FORMAT
      );
    },
    spentOn() {
      return dayjs(this.log.spent_on.date).format(Config.DATE_FORMAT);
    },
    action() {
      return ACTION_TITLES[this.log.action];
    },
  },
  methods: {
    selectIssue() {
      this.$emit("selectIssue", this.log.issue_id);
    },
  },
};
