<template>
  <div>
    <top-bar />
    <div class="m-4">
      <div class="text-center">
        <clink class="btn" active-class="btn-dark" :to="{ name: 'log-issues' }">Tâches</clink>
        <clink class="btn ml-2" active-class="btn-dark" :to="{ name: 'log-timeentries' }">Saisies</clink>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "Logs",
};
</script>
