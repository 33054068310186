import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";
import FlatPickr from "vue-flatpickr-component";
import { French } from "flatpickr/dist/l10n/fr.js";
import dayjs from "dayjs";
import { DATE_FORMAT } from "@/config/constants";
import ResourceSelect from "@/views/common/ResourceSelect.vue";
import LogTimeentriesLine from "./LogTimeentriesLine.vue";
import IssueModal from "@/views/calendar/IssueModal.vue";
import IconRefresh from "@/icons/arrow-rotate-right-solid.svg";

function getDefaultData() {
    return {
        filters: {
            project: null,
            user: null,
            spentOnFrom: dayjs().subtract(7, 'day').format(DATE_FORMAT),
            spentOnTo: '',
        },
        dateConfig: {
            locale: French,
        },
        logs: [],
        selectedIssueId: null,
    };
}

export default {
    name: "LogTimeentries",
    components: {
        Multiselect,
        FlatPickr,
        ResourceSelect,
        LogTimeentriesLine,
        IssueModal,
        IconRefresh,
    },
    data: getDefaultData,
    computed: {
        // @ts-ignore
        ...mapGetters({
            projects: "Project/list/projects",
        }),
    },
    methods: {
        refreshLogs() {
            let payload = {
                user_id: this.filters.user?.id,
                project_id: this.filters.project?.id,
                spent_on_from: this.filters.spentOnFrom,
                spent_on_to: this.filters.spentOnTo,
            };
            Object.keys(payload).forEach((key) => !payload[key] && delete payload[key]);
            this.$store.dispatch('TimeEntry/list/getLogs', payload).then((data) => {
                this.logs = data.data;
            });
        },
        resetFilters() {
            Object.assign(this.filters, getDefaultData().filters);
        },
        selectIssue(issueId) {
            this.selectedIssueId = issueId;
        },
        closeIssueModal() {
            this.selectedIssueId = null;
        },
    },
    watch: {
        filters: {
            deep: true,
            handler() {
                this.refreshLogs();
            }
        },
    },
    mounted() {
        this.$store.dispatch("Project/list/getList");
        this.refreshLogs();
    },
}
