import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";
import FlatPickr from "vue-flatpickr-component";
import { French } from "flatpickr/dist/l10n/fr.js";
import dayjs from "dayjs";
import LogIssuesLine from "./LogIssuesLine.vue";
import { ISSUE_FIELD_LABELS } from "@/config/redmine-constants";
import { DATE_FORMAT } from "@/config/constants";
import IssueModal from "@/views/calendar/IssueModal.vue";
import IconRefresh from "@/icons/arrow-rotate-right-solid.svg";

function getDefaultData() {
    return {
        filters: {
            project: null,
            issue: null,
            property: null,
            whenFrom: dayjs().subtract(7, 'day').format(DATE_FORMAT),
            whenTo: '',
        },
        dateConfig: {
            locale: French,
        },
        issues: [],
        logs: [],
        selectedIssueId: null,
    };
}

const alphaSortName = (a: any, b: any) => {
    var textA = a.name.toUpperCase();
    var textB = b.name.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
};

export default {
    name: "LogIssues",
    components: {
        Multiselect,
        FlatPickr,
        LogIssuesLine,
        IssueModal,
        IconRefresh,
    },
    data: getDefaultData,
    computed: {
        // @ts-ignore
        ...mapGetters({
            projects: "Project/list/projects",
            resources: "Resource/list/resources",
            customFields: "Enumeration/customFields/customFields",
        }),
        customFieldProperties() {
            return Object.values(this.customFields)
                .filter((cf: any) => cf.customized_type === 'issue')
                .map((cf: any) => ({ id: cf.id, name: cf.name, type: 'cf' }));
        },
        standardFieldProperties() {
            return Object.entries(ISSUE_FIELD_LABELS)
                .map((kv: any) => ({ id: kv[0], name: kv[1], type: 'attr' }));
        },
        properties() {
            return this.customFieldProperties
                .concat(this.standardFieldProperties)
                .sort(alphaSortName)
                ;
        }
    },
    methods: {
        selectProject() {
            this.filters.issue = null;
            if (!this.filters.project) {
                this.issues = [];
                return;
            }
            this.$store.dispatch("Issue/list/getList", {
                projectId: this.filters.project.id,
                includeClosed: true,
            }).then((issues) => {
                this.issues = issues;
            });
        },
        refreshLogs() {
            let payload = {
                project_id: this.filters.project?.id,
                issue_id: this.filters.issue?.id,
                property: this.filters.property?.type,
                prop_key: this.filters.property?.id,
                when_from: this.filters.whenFrom,
                when_to: this.filters.whenTo,
            };
            Object.keys(payload).forEach((key) => !payload[key] && delete payload[key]);
            this.$store.dispatch('Issue/list/getJournals', payload).then((data) => {
                this.logs = data.data;
            });
        },
        resetFilters() {
            Object.assign(this.filters, getDefaultData().filters);
        },
        selectIssue(issueId) {
            this.selectedIssueId = issueId;
        },
        closeIssueModal() {
            this.selectedIssueId = null;
        },
    },
    watch: {
        filters: {
            deep: true,
            handler() {
                this.refreshLogs();
            }
        },
    },
    mounted() {
        this.$store.dispatch("Enumeration/customFields/getList");
        this.$store.dispatch("Project/list/getList");
        this.refreshLogs();
    },
}
