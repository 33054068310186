













import dayjs from "dayjs";
import { PropType } from "vue";
import { mapGetters } from "vuex";
import { JournalLine } from "@/app/types/interfaces";
import { ISSUE_FIELD_LABELS } from "@/config/redmine-constants";
import * as Config from "@/config/constants";

export default {
  name: "JournalLine",
  props: {
    log: {
      type: Object as PropType<JournalLine>
    }
  },
  computed: {
    // @ts-ignore
    ...mapGetters({
      customFields: "Enumeration/customFields/customFields",
    }),
    when() {
      return dayjs(this.log.when).format(Config.DATE_FORMAT + " à " + Config.TIME_FORMAT);
    },
    field() {
      if (this.log.property === 'cf' && this.customFields.hasOwnProperty(this.log.prop_key)) {
        return this.customFields[this.log.prop_key].name;
      }
      if (this.log.property === 'attr' && ISSUE_FIELD_LABELS.hasOwnProperty(this.log.prop_key)) {
        return ISSUE_FIELD_LABELS[this.log.prop_key];
      }
      if (this.log.property === 'attachment') {
        return 'Document';
      }
      return this.log.property + ' - ' + this.log.prop_key;
    },
  },
  methods: {
    selectIssue() {
      this.$emit("selectIssue", this.log.issue_id);
    },
  },
};
